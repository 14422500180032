<template>
  <div>
    <v-card class="pa-8" v-if="!loading">
      <header-content :pageTitle="'List Pembayaran'" />

      <div class="d-flex">
        <v-text-field
          v-model="searchQuery"
          outlined
          rounded
          dense
          color="primary"
          label="Cari kata kunci disini"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <v-btn
          color="primary"
          rounded
          class="ml-4 white--text d-none d-lg-block text-none"
          @click="$router.push('/input-payment')"
        >
          Tambah Pembayaran
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="ml-4 white--text d-lg-none d-md-block text-none"
          @click="$router.push('/input-payment')"
        >
          Tambah
        </v-btn>
      </div>

      <v-data-table
        class="cursor-pointer"
        :headers="headers"
        :items="payments"
        :items-per-page="10"
        :search="searchQuery"
        @click:row="toDetail"
      ></v-data-table>

      <v-btn
        color="primary"
        rounded
        :disabled="isAllLoaded"
        class="ml-4 white--text text-none"
        @click="loadMore"
      >
        Load More Data
      </v-btn>

      <v-btn
        color="primary"
        rounded
        :disabled="isAllLoaded"
        class="ml-4 white--text text-none"
        @click="loadAll"
      >
        Load All Data
      </v-btn>
    </v-card>
    <v-card class="pa-8" v-else>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
import store from "../../store/index.js";
import converters from "../../helpers/converters.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";

export default {
  components: { HeaderContent },

  data() {
    return {
      searchQuery: "",
      loading: true,
      isAllLoaded: false,
      headers: [
        {
          text: "ID Pembayaran",
          align: "start",
          sortable: true,
          value: "id_pembayaran",
        },
        { text: "Nilai Pembayaran", value: "nilai_pembayaran" },
        {
          text: "Sisa Pinjaman Berjalan",
          value: "sisa_pinjaman_berjalan_formatted",
        },
        {
          text: "Tanggal Pelunasan Penyelenggara",
          value: "tgl_pelunasan_penyelenggara_formatted",
        },
        {
          text: "Tanggal Pembayaran Penyelenggara",
          value: "tgl_pembayaran_penyelenggara_formatted",
        },
        { text: "Tanggal Jatuh Tempo", value: "tgl_jatuh_tempo_formatted" },
      ],
      payments: [],
      paymentsPagination: {
        firstPage: 1,
        lastPage: 100,
      },
    };
  },

  methods: {
    toDetail(item) {
      this.$router.push("/edit-payment/" + item.id);
    },
    async getPayment() {
      this.loading = true;

      await store
        .dispatch("office/fetchListPayment", this.paymentsPagination)
        .then((r) => {
          if (r) {
            this.loading = false;
          }
        });
      this.payments = store.state.office.listPayment;
      this.payments.forEach((a) => {
        a.tgl_pelunasan_penyelenggara_formatted = converters.date(
          a.tgl_pelunasan_penyelenggara
        );
        a.tgl_pembayaran_penyelenggara_formatted = converters.date(
          a.tgl_pembayaran_penyelenggara
        );
        a.tgl_jatuh_tempo_formatted = converters.date(a.tgl_jatuh_tempo);
        a.sisa_pinjaman_berjalan_formatted = converters.currency(
          a.sisa_pinjaman_berjalan
        );
      });
    },
    loadMore() {
      if (this.paymentsPagination.lastPage <= 30000) {
        this.paymentsPagination.lastPage += 1000;
        this.getPayment();
      } else {
        this.isAllLoaded = true;
      }
    },
    loadAll() {
      if (this.paymentsPagination.lastPage <= 30000) {
        this.paymentsPagination.lastPage += 30000;
        this.getPayment();
        this.isAllLoaded = true;
      }
    },
  },

  mounted() {
    this.getPayment();
  },
};
</script>
